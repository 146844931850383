import React, { useEffect, useState } from "react";
import styles from "./agentconsole.module.css";
import SourcingConsole from "./sourcingagent/SourcingConsole";
import ProfileRanker from "../../hooks/ProfileRanker";
import Progressbar from "../../components/progressbar/Progressbar";

function Agent() {
  const [data, setData] = useState([]);
  const [orgin, setOrgin] = useState(true);
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);

  function uniqBy(array, key) {
    const seen = new Set();
    return array.filter(item => {
      const keyValue = item[key];
      // Omit the item if the key value is missing, undefined, or an empty string
      if (keyValue === undefined || keyValue === '') {
        return false;
      }
      if (seen.has(keyValue)) {
        return false;
      } else {
        seen.add(keyValue);
        return true;
      }
    });
  }

  useEffect(() => {
    function collecter(event) {
      if (event.detail.action === "rank_search") {
        let datas = event?.detail?.payload?.dataArray;


        let payloadProfiles = datas.reduce((acc,cur) => 
          acc.concat(cur),[]);

        console.log('profiles from extension', payloadProfiles);

        let profiles = uniqBy(payloadProfiles, 'uniqueId');

        console.log('profiles for ranking', profiles);

        

        let reqFields = [
          "noticePeriod",
          "numberOfViews",
          "numberOfDownloads",
          "experience",
          "ctcinfo",
          "currentLocation",
          "preferredLocations",
          "jsUserName",
          "jsUserId",
          "jobTitle",
          "keySkills",
          "focusedSkills",
          "uniqueId",
          "education",
          "employment",
          "activeDateMillis",
          "modifyDateMillis",
          "mobileVerifiedDateMillis",
          "url"
        ];

        let jobSpec = event?.detail?.data?.custom_entries;

       
          
          jobSpec.minimum_experience = event?.detail?.data?.minimum_experience;
          jobSpec.maximum_experience = event?.detail?.data?.maximum_experience;
          jobSpec.locations = event?.detail?.data?.location;
          jobSpec.notice_period = event?.detail?.data?.notice_period;
          jobSpec.max_ctc = event?.detail?.data?.current_ctc;
        

        let buffer = profiles.map((obj) => {
          let newObj = { checked: false };
          reqFields.forEach((field) => (newObj[field] = obj[field]));
          newObj.ctcinfo = obj.ctcInfo;
          delete newObj.ctcInfo;
          return newObj;
        });

        const weightage = {
          profile_title: 1,
          required_skills: 1,
          preferred_skills: 1,
          experience: 1,
          location: 1,
          ctc: 1,
        };

        console.log(jobSpec, buffer, weightage);

        setOrgin(false);
        setProgress(10);

        const rankProfiles = async () => {
          const ranker = new ProfileRanker();
          await ranker.initializeModel();
          const rankedProfiles = await ranker.score(jobSpec, buffer, weightage, (progress) => setProgress(progress));
          console.log(rankedProfiles);
          const trans = rankedProfiles.map((i) => {
            const rankedjsid = Object.keys(i)[0];
            const found = buffer.find((j) => String(j.jsUserId) == rankedjsid);
            return found;
          });
          setData([
            { name: "Our Top Pick", items: [...trans.slice(0, 20)] },
            { name: "Has Potential", items: [...trans.slice(20, 60)] },
            { name: "Seems To Fit", items: [...trans.slice(60, 100)] },
          ]);
          setLoading(false);
        };
        rankProfiles();
      }
    }

    document.addEventListener("host", collecter);

    return () => {
      document.removeEventListener("host", collecter);
    };
  }, []);

  return (
    <div className={`${styles.container}`}>
      {orgin && (
        <div className={`${styles.default_container}`}>
          <div className={`${styles.heading}`}>This is the Agent Console!</div>
          <div className={`${styles.info}`}>
            All the vital data processed by our agents will be presented here in
            real-time.
            <br/> Currently, there are no active agents, but stay tuned –
            dynamic updates are just around the corner!
          </div>
        </div>
      )}

      {loading && !orgin && (<div className={`${styles.default_container}`}>
        <div className={`${styles.heading}`}>
          Sourcing Agent
        </div>
        <div className={`${styles.progress}`}>
        <Progressbar title={'Total progress'} progress={progress} showMarking={true} showLevel={true} />
        </div>
        <div>
            Scoring Progress: {progress}%
        </div>
        
      </div>)}
      
      {!loading && !orgin && <SourcingConsole data={data} setData={setData} />}
    </div>
  );
}

export default Agent;
